var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.report)?_c('table',{staticClass:"width-auto"},[_vm._l((_vm.report),function(item,index){return [_c('tr',{key:item.id},[_c('td',{staticClass:"text-xs-center",attrs:{"colspan":"3"}},[_c('b',[_vm._v(_vm._s(item.name))])])]),_c('tr',{key:item.id},[_vm._l((_vm.tableHeader),function(th,indexTh){return [_c('td',{key:`th-${indexTh}`},[_c('b',[_vm._v(_vm._s(th))])])]})],2),_vm._l((item.values),function(val,key){return [_c('tr',{key:`value-${item.id}-${key}`},[_c('td',[_vm._v(_vm._s(val.decorPercent))]),_c('td',[_vm._v(_vm._s(val.BouquetsCount))]),_c('td',[_vm._v(_vm._s(val.AllBouquetsCoast))])])]}),_c('tr',{key:item.id},[_vm._m(0,true),_c('td',[_c('b',[_vm._v(_vm._s(item.totalBouquetsCount))])]),_c('td',[_c('b',[_vm._v(_vm._s(item.totalAllBouquetsCoast))])])]),_c('tr',{key:item.id},[_vm._m(1,true),_c('td',[_c('b',[_vm._v(_vm._s(Math.round(item.totalAllBouquetsCoast / item.totalBouquetsCount)))])])]),_c('tr',{key:item.id},[_vm._m(2,true),_c('td',[_c('b',[_vm._v(_vm._s(Math.round(item.totalBouquetsCount / _vm.days)))])])])]}),_vm._m(3),_c('tr',[_vm._m(4),_c('td',[_c('b',[_vm._v(_vm._s(_vm.averageBouquetsCount))])])]),_c('tr',[_vm._m(5),_c('td',[_c('b',[_vm._v(_vm._s(_vm.averageAllBouquetsCoast))])])]),_c('tr',[_vm._m(6),_c('td',[_c('b',[_vm._v(_vm._s(Math.round(_vm.averageAllBouquetsCoast / _vm.averageBouquetsCount)))])])]),_c('tr',[_vm._m(7),_c('td',[_c('b',[_vm._v(_vm._s(Math.round(_vm.averageBouquetsCount / _vm.days)))])])])],2):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("Итого")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"2"}},[_c('b',[_vm._v("Средний чек")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"2"}},[_c('b',[_vm._v("Среднее количество"),_c('br'),_vm._v("букетов в день")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-xs-center",attrs:{"colspan":"3"}},[_c('b',[_vm._v("Среднее по всем флористам")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"2"}},[_c('b',[_vm._v("Количество букетов")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"2"}},[_c('b',[_vm._v("Стоимость букетов")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"2"}},[_c('b',[_vm._v("Средний чек")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"2"}},[_c('b',[_vm._v("Среднее количество"),_c('br'),_vm._v("букетов в день")])])
}]

export { render, staticRenderFns }