<template>
  <!-- <div>
    <table
      class="width-auto"
      v-if="report"
    >
      <tr>
        <template v-for="(item, index) in tableHeader">
          <th :key="`th-${index}`" class="column sortable asc active" @click="sort(item)">{{ item }}</th>
        </template>
      </tr>
      <template v-for="(item, index) in report">
        <tr :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.sum }}</td>
          <td>{{ item.quantity }}</td>
        </tr>
      </template>
    </table>
  </div> -->
  <v-data-table
          :headers="headersTable"
          :items="report"
          hide-actions
          no-data-text="Оплат не найдено"
          no-results-text="Оплат не найдено"
          :pagination.sync="pagination"
        >
      <template slot="items" slot-scope="props">
          <td class="text-xs-right" style="width: 30px;">
            {{ props.item.id }}
          </td>
          <td class="text-xs-right" style="width: 30px;">
            {{ props.item.name }}
          </td>
          <td class="text-xs-right" style="width: 30px;">
            {{ formatNumber(props.item.sum) }}
          </td>
          <td class="text-xs-right" style="width: 30px;">
            {{ formatNumber(props.item.quantity) }}
          </td>
        </template>
        <v-layout row wrap justify-space-around class="py-2">
          <v-flex xs2 class="px-3">
            <v-text-field
              label="Количество на странице"
              v-model.number="take"
              hide-details
              @change="changeShowElem()"
            ></v-text-field>
          </v-flex>
          <v-flex xs10 class="text-xs-right px-3">
            <v-btn
              small
              color="info"
              class="ml-3"
              :disabled="page === 1"
              @click="prevPage()"
            >
              <v-icon dark>keyboard_arrow_left</v-icon>
            </v-btn>
            <v-btn
              small
              color="info"
              class="ml-3"
              :disabled="report.length < take"
              @click="nextPage()"
            >
              <v-icon dark>keyboard_arrow_right</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
  </v-data-table>
</template>

<script>
import axios from 'axios';
import format from "date-fns/format";
import { ru } from "date-fns/locale";

export default {
  props: {
    dateStart: {
      type: String,
      required: true,
    },
    dateEnd: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // tableHeader: [
      //   '№',
      //   'Имя',
      //   'Сумма',
      //   'Количество',
      // ],
      // report: [],
      // sortColumn: '',
      // sortOrder: 'asc',
      report: [],
      headersTable: [
        {
          text: "ID",
          align: "right",
          value: "id",
          filterable: false,
          sortable: true,
        },
        {
          text: "Имя",
          align: "left",
          value: "name",
          filterable: false,
          sortable: true,
        },
        {
          text: "Сумма",
          align: "left",
          value: "sum",
          sortable: true,
        },
        {
          text: "Количество",
          align: "left",
          value: "quantity",
          sortable: true,
        },
      ],
      pagination: {
        sortBy: "id",
        rowsPerPage: -1,
        descending: true,
      },
      take: 20,
      page: 1,
      tableLoading: false,
    };
  },
  methods: {
    formatNumber(value) {
      if (Number.isInteger(value)) {
        return value;
      } else {
        return Number(value).toFixed(2);
      }
    },
    changeShowElem() {
      localStorage.setItem("countElemPage", this.take);
      this.$store.commit("setCountElemPage", this.take);
      this.page = 1;
      this.getPaymentsList();
    },
    prevPage() {
      this.page -= 1;
      this.getPaymentsList();
    },
    nextPage() {
      this.page += 1;
      this.getPaymentsList();
    },
    sort(column) {
      if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
        // alert(0)
      } else {
        // alert(1)
        this.sortColumn = column;
        this.sortOrder = 'asc';
      }
      console.log('column',column)
      // Call a method to reorganize the data based on the sorting criteria
      this.sortReport();
    },
    sortReport() {
      if (this.sortColumn) {
        this.report.sort((a, b) => {
          const order = this.sortOrder === 'asc' ? 1 : -1;
          return a[this.sortColumn] > b[this.sortColumn] ? order : -order;
        });
      }
    },
    formatDate(date, dateFormat) {
      return format(new Date(date), dateFormat, { locale: ru });
    },
    getReportList() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/reports`;
      const propsItem = {
        report_type_id: 1, //goods
        start_date: this.dateStart,
        end_date: this.dateEnd,
      };
      axios
        .get(url, {
          params: propsItem,
        })
        .then((response) => {
          this.report = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getReportList();

    this.$parent.$on('reportTypeChange', () => {
      this.getReportList();
    });
  },
  watch: {
    // Watch for changes in dateStart and dateEnd props
    dateStart(newValue, oldValue) {
      this.getReportList();
    },
    dateEnd(newValue, oldValue) {
      this.getReportList();
    },
    pagination: {
      handler() {
        this.page = 1;

        this.getReportList();
      },
      deep: true,
    },
  },
};
</script>
